@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Monoton&family=Rowdies:wght@300&display=swap');

@font-face {
  font-family: Helvetica;
  src: url('assets/fonts/helveticaneue/HelveticaNeue-Light.otf');
  font-weight: 100;
}

@font-face {
  font-family: Helvetica;
  src: url('assets/fonts/helveticaneue/HelveticaNeue-UltraLight.otf');
  font-weight: 300;
}

@font-face {
  font-family: Helvetica;
  src: url('assets/fonts/helveticaneue/HelveticaNeue-Light.otf');
  font-weight: 400;
}

@font-face {
  font-family: Helvetica;
  src: url('assets/fonts/helveticaneue/HelveticaNeue-Regular.otf');
  font-weight: 500;
}

@font-face {
  font-family: Helvetica;
  src: url('assets/fonts/helveticaneue/HelveticaNeue-Bold.otf');
  font-weight: 700;
}

* {
  font-family: Helvetica;
}

::-webkit-scrollbar {
  display: none;
}

.text-purple {
  color: #713198;
}

/* GENERAL STYLING OPTIONS*/

:focus:not(input) {
  outline: none !important;
  outline: 0px !important;
  -webkit-appearance: none;
}

html {
  background-color: #240735;
  overflow: hidden;
  width: 100%;
}

body {
  padding: 0;
  background-color: #240735;
  font-size: 16px;
  color: white;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-background-size: cover;
  /* background-position: center; */
  /* background-position: 50% 0; */
  background-size: cover;
  background-image: url('assets/images/svg/background-with-opacity.svg');
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

#root {
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
}

.header {
  background: #713198;
  background-image: url('assets/images/svg/background-with-opacity.svg');
  background-size: cover;
  padding: 15px 0px 15px 0px;
  background-repeat: no-repeat;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* position: fixed; */
  width: 100%;
  /* top: 0; */
  /* left: 0; */
  z-index: 1000;
  flex-shrink: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.46);
}

.play-info {
  padding: 8px 5px;
  /* padding-bottom: 8px; */
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #150121;
}

.play-info svg {
  margin-right: 5px;
}

.header-parent {
  background: #fffffff1;
  z-index: 101;
  /* position: fixed; */
  width: 100%;
  /* top: 0; */
  /* left: 0; */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 46%);
}

.header-parent .header {
  position: relative;
  top: unset;
  left: unset;
}

.main-container {
  background-color: #15012188;
  /* top: 0; */
  /* bottom: 0; */
  z-index: 100;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  /* -webkit-overflow-scrolling: auto; */
}

.footer {
  background-color: #713198;
  background-image: url('assets/images/svg/background-with-opacity.svg');
  background-size: cover;
  background-position: left bottom;
  padding-bottom: 10px;
  width: 100%;
  flex-shrink: 0;
  /* position: fixed; */
  z-index: 101;
  /* bottom: 0px; */
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.46);
}

.container {
  box-sizing: border-box;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  height: 100%;
  padding-right: 8px;
}

.last-game-info,
.current-game-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.game-title {
  color: #de9411;
  font-size: 18px;
  font-weight: 400;
}

.game-title.new {
  color: white;
  margin-top: -3px;
  font-size: 16px;
  margin-bottom: 5px;
  position: absolute;
  left: 12px;
  top: -9px;
  background: #2d217e;
  border: 1px solid white;
  border-radius: 12px;
  padding: 2px 25px;
  padding-right: 20px;
  padding-bottom: 4px;
  font-weight: 400;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 25%);

  background: linear-gradient(180deg, #6262ff 0%, #282874 100%);
  border: 1px solid rgba(255, 255, 255, 0.64);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px -3px 0px rgba(0, 0, 0, 0.37);
  border-radius: 12px;
}

.game-title.new2 {
  display: flex;
  color: white;
  font-size: 16px;
  margin-right: auto;
  position: relative;
  height: 35px;
  align-items: center;
  /* left: 12px; */
  /* background: #2d217e; */
  border: 1px solid white;
  border-radius: 12px;
  padding: 2px 14px;
  padding-right: 10px;
  padding-bottom: 4px;
  border: none;
  font-weight: 400;
  box-shadow: 2px 0px 1px 0px rgb(0 0 0 / 33%);
  background: linear-gradient(180deg, #ffe3b1 -20%, #d9720a 100%);
  /* border: 1px solid rgba(255, 255, 255, 0.64); */
  /* box-sizing: border-box; */
  border-radius: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 73px;
}

@keyframes glow-new {
  from {
    box-shadow: 0 0 3px 0px red;
  }
  to {
    box-shadow: 0 0 3px 3px red;
  }
}

.game-title.new span,
.game-title.new2 span {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  padding-left: 0px;
  font-size: 20px !important;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-shadow: 0px 1px #de9411;
}
.game-title.new span.type,
.game-title.new2 span.type {
  font-family: 'Helvetica';
  padding-right: 0px;
  font-weight: 400;
  margin-top: 2px;
  font-size: 14px !important;
  padding-top: 1px;
  color: #ffffffc9;
  text-shadow: none;
}

.game-title.new span.manat,
.game-title.new2 span.manat {
  font-size: 13px;
}

.game-title.new2 span.new-label {
  position: absolute;
  background: red;
  right: 0px;
  font-size: 8px !important;
  border-radius: 20px;
  padding: 2px 11px;
  line-height: 14px;
  top: -10px;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 33%);
}

.game-prize {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
}

.current-game-stats > .game-title.blue {
  background: #2d217e;
}

.current-game-stats > .game-title.yellow {
  background: #de9411;
}

.game-prize > .prize {
  font-size: 23px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: white;

  /* text-shadow: 0 0 5px #ff0018, 0 1px red, 1px 0px red, -1px 0px red, 0px -1px red; */
  /* text-shadow: 0 0 0px black, 0 1px black, 1px 0px black, -1px 0px black, 0px -1px black; */
  font-weight: 700;
  text-shadow: 0 0 8px red, 0 1px red, 1px 0px red, -1px 0px red, 0px -1px red;
}

.game-container .game-prize > .prize {
  /* color: #606060; */
  /* text-shadow: none; */

  font-weight: 700;
  text-shadow: 0 0 8px red, 0 1px red, 1px 0px red, -1px 0px red, 0px -1px red;
}

.game-prize > .prize > .count {
  font-weight: bold;
  font-size: 30px;
  color: #07ba23;
  text-shadow: 1px 1px #0b621f;
}

.count svg {
  fill: #07ba23;
  color: #07ba23;
  filter: drop-shadow(1px 1px 0px #0b621f);
}

.game-prize > .online-users {
  font-size: 20px;
  color: #606060;
  line-height: 30px;
}

.game-prize > .online-users > .count {
  font-size: 30px;
  font-weight: bold;
}

.current-game-info > .game-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-prize > .game-info {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.game-info > .clock-info,
.game-info > .ticket-price-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.game-info > .clock-info {
  /* margin-top: -8px; */
  margin-right: 10px;
}

.game-info > .ticket-price-info {
  margin-top: 5px;
}

.game-info .label {
  font-size: 9px;
  line-height: 8px;
  color: #ffffff;
  font-weight: 400;
  background: #444444;
  padding: 4px 10px;
  border-radius: 10px;
  margin-right: -5px;
}

.game-info .label.starting {
  background: #dc3545;
}

.game-info .clock.starting {
  color: #dc3545;
}

.game-info .label.started {
  background: #7f8386;
}
.game-info .clock.started {
  color: #7f8386;
}

.game-info .clock,
.game-info .ticket-price {
  font-size: 17px;
  font-weight: bold;
  color: #444444;
}

.img-money-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.img-money {
  width: auto;
  margin-top: auto;
}

.current-game-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.last-game-prize {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.total-price {
  display: flex;
  flex-direction: column;
}

.total-price span.label {
  font-size: 15px;
  margin: 0px;
  padding-left: 4px;
  font-weight: 200;
}

.total-price span.amount {
  font-size: 27px;
  padding-left: 4px;
  line-height: 25px;
  margin: 0px;
  font-weight: 500;
}

.last-game-prize > .prize {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(89, 201, 158);
  background: linear-gradient(
    0deg,
    rgba(89, 201, 158, 1) -20%,
    rgba(152, 224, 196, 1) 100%
  );
  color: white;
  border-radius: 50px;
  margin-top: auto;
  font-weight: 500;
  margin-right: -5px;
  font-size: 16px;
  padding: 4px 14px;
  text-shadow: 0px 1px 1px #28a745;
}

.last-game-numbers {
  display: flex;
  flex-direction: column;
}

.last-game-numbers > .numbers {
  display: flex;
}

.last-game-numbers > .label,
.last-game-prize > .label {
  color: #713198;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.last-game-prize > .label {
  align-self: flex-end;
}

.last-game-numbers > .numbers > .ball {
  display: flex;
  border: 3px solid #495057;
  margin: 2px;
  border-radius: 50px;
  font-weight: bold;
  color: #495057;
  /* color: white; */
  align-items: center;
  font-size: 13px;
  justify-content: center;
  height: 28px;
  width: 28px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.38);
}

.gradient {
  padding: 4px;
  background: linear-gradient(180deg, #ffe3b1 -20%, #d9720a 100%);
  border-radius: 31px;
}

.gradient.game_0_20_azn {
  background-color: #08aeea;
  background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
}

.game_0_20_azn .ball {
  background: green;
}

.gradient.game_1_azn {
  background-color: #a9c9ff;
  background-image: linear-gradient(180deg, #a9c9ff 0%, #ffbbec 100%);
}
.gradient.game_5_azn {
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%);
}

.gradient.game_10_azn {
  background-image: linear-gradient(to top, #0fd850 0%, #f9f047 100%);
}

.card {
  position: relative;
  border-radius: 28px;
  background-color: white;
  padding: 12px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
}

.gradient .card {
  background: #ffffff;
}

.divider {
  height: 1px;
  background-color: #b5b5b5;
  margin-top: 0px;
  opacity: 0.5;
  margin-bottom: 5px;
}

.card-divider {
  height: 1px;
  background-color: #b5b5b5;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-container {
  /* padding-top: 94px; */
  /* padding-bottom: 80px; */
  height: 100%;
}

.game-container {
  position: relative;
  margin-bottom: 10px;
}

.game-container.starting .card {
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 25%), 0px 0px 3px 4px red;
}

/* .game-container.started:after {
  content: '';
  position: absolute;
  background: #495057b5;
  filter: blur();
  width: 100px;
  border-radius: 29px;
  z-index: 1000;
  bottom: 0;
  margin: 0px;
  top: 0;
  left: 3px;
  right: 3px;
} */

.ticket-container {
  padding-top: 10px;
}

.jackpot-bar {
  border: 1px solid white;
  border-radius: 21px;
  height: 25px;
  font-size: 14px !important;
}

.username-container {
  /* background: #470d6a; */
  border-radius: 21px;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  background: linear-gradient(180deg, #467afc 0%, #2d217e 100%);
  color: white;
  height: 29px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}

.username-container img {
  height: 25px;
  width: 25px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 100px;
}

.username {
  font-size: 12px;
  color: white;
  /* font-weight: bold;*/
  padding: 15px;
  margin-left: -6px;
}

.balance-container {
  display: flex;
  position: relative;
  flex-direction: row;
  background: rgb(255, 227, 177);
  background: linear-gradient(
    180deg,
    rgba(255, 227, 177, 1) 0%,
    rgba(222, 148, 17, 1) 100%
  );
  border-radius: 21px;
  height: 29px;
  padding: 3px;
  padding-right: 5px;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}
.balance-container::after {
  content: '+';
  padding: 5px;
  font-size: 22px;
  color: #414141;
  width: 100%;
  margin-top: -1px;
}

.balance-container .balance {
  display: flex;
  font-family: 'Dosis';
  height: 100%;
  background: white;
  border-radius: 21px;
  align-items: center;
  color: #cb9032;
  font-weight: 700;
  padding: 0px 10px;
  font-size: 16px;
}

.logo-container {
  position: relative;
}

.logo-container img {
  height: 35px;
  margin-top: 5px;
}

.clock-label {
  position: absolute;
  right: 13px;
  /* margin-right: 5px; */
  background: #eb1818;
  border-radius: 30px;
  padding: 2px 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.39);
  font-size: 12px;
  top: -9px;
  font-weight: 400;
}

.bottom-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-around;
}

.bottom-bar .tab,
a {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*

.bottom-bar .tab .leaderboard {
  content: url("assets/images/icons/leaderboard.png");
}

.bottom-bar .tab a.active .leaderboard {
  content: url("assets/images/icons/leaderboardSelected.png");
}

.bottom-bar .tab .chat {
  content: url("assets/images/icons/chat.png");
}

.bottom-bar .tab a.active .chat {
  content: url("assets/images/icons/chatSelected.png");
}

.bottom-bar .tab .settings {
  content: url("assets/images/icons/settings.png");
}

.bottom-bar .tab a.active .settings {
  content: url("assets/images/icons/settingsSelected.png");
}

.bottom-bar .tab .profile {
  content: url("assets/images/icons/profile.png");
}

.bottom-bar .tab a.active .profile {
  content: url("assets/images/icons/profileSelected.png");
}
*/

.bottom-bar .tab img {
  width: 30px;
  height: 30px;
}

.bottom-bar .tab a .main {
  background: white;
  border: 4px solid #40145c;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  background: rgb(192, 83, 255);
  background: linear-gradient(
    180deg,
    rgb(105 24 152) 0%,
    rgba(96, 42, 128, 1) 100%
  );
  background-color: rgba(96, 42, 128, 1);
  border-radius: 100px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.46);
}

.bottom-bar .tab a.active .main {
  background: linear-gradient(
    180deg,
    rgba(192, 83, 255, 1) 0%,
    rgba(96, 42, 128, 1) 100%
  );
}

.bottom-bar .tab a span {
  color: #3c1e57;
  font-weight: bold;
}

.bottom-bar .tab a:hover {
  text-decoration: none;
}

.bottom-bar .tab a svg {
  width: 30px;
  height: 30px;
}

.bottom-bar .tab a.active span {
  color: white;
}

.bottom-bar .tab a path {
  fill: #4e206b;
}

.bottom-bar .tab a.active path {
  fill: white;
}

.bottom-bar .tab .main svg {
  width: 35px;
  height: 35px;
}
.bottom-bar .tab a .main path {
  fill: none !important;
}

.bottom-bar .tab a.active .main path {
  stroke: white;
}

.bottom-bar .tab .main .keg {
  content: url('assets/images/icons/keg.png');
  width: 30px;
  height: 38px;
}
/*
.bottom-bar .tab .main a.active .keg {
  content: url("assets/images/icons/kegSelected.png");
  width: 30px;
  height: 38px;
}
*/

.bottom-bar .tab span {
  color: white;
  font-size: 10px;
}

.rounded-bar {
  position: relative;
  border-radius: 30px;
  color: white;
  padding: 10px 18px;
  font-size: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.rounded-bar.purple {
  background: rgb(192, 83, 255);
  background: linear-gradient(
    180deg,
    rgba(192, 83, 255, 1) 0%,
    rgba(96, 42, 128, 1) 100%
  );
  background-color: rgba(96, 42, 128, 1);
}

.rounded-bar.blue {
  background: rgb(92, 166, 245);
  background: linear-gradient(
    180deg,
    rgba(92, 166, 245, 1) 0%,
    rgba(0, 109, 226, 1) 100%
  );
}

.rounded-bar.green {
  background: rgb(0, 200, 93);
  background: linear-gradient(
    180deg,
    rgba(0, 200, 93, 1) 0%,
    rgba(2, 143, 50, 1) 100%
  );
}

.price {
  font-size: 20px;
  font-weight: 600;
}

.header-container {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 5px;
  padding-bottom: 0px;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
}

.header-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.online-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
  font-size: 13px;
}

.online-players {
  font-size: 25px;
  color: white;
  font-weight: 600;
}

.jackpot-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0px;
}

.jackpot-container span {
  display: flex;
  flex: 1;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 4px;
  margin: 5px 2px;
  padding-bottom: 4px;
}

.header-bottom-extra {
  /* height: 40px; */
  padding-left: 5px;
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ffffff99;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.glass:after {
  content: '';
  position: absolute;
  width: 92%;
  height: 60%;
  left: 4%;
  top: 0;
  -webkit-border-radius: 0.5em 0.5em 1em 1em / 0.5em 0.5em 2em 2em;
  -moz-border-radius: 0.5em 0.5em 1em 1em / 0.5em 0.5em 2em 2em;
  /* border-radius: 0.5em 0.5em 1em 1em / 0.5em 0.5em 2em 2em; */
  border-radius: 17px;
  background-color: rgb(255, 255, 255, 0.17);
}

.card.label:after {
  content: 'hello';
  position: absolute;
}

.current-game-clock {
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
}

.current-clock {
  font-size: 25px;
  font-weight: bold;
}

.font-size-25 {
  font-size: 25px;
  font-weight: 600;
}

.current-players {
  font-size: 20px;
  color: #003d80;
}

.rounded-bar.black {
  background: rgb(84, 77, 77);
  background: linear-gradient(
    180deg,
    rgba(84, 77, 77, 1) 0%,
    rgba(0, 2, 4, 1) 100%
  );
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  right: 0;
  background: #24073575;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #14001f;
  height: 90px;
  width: 90px;
  margin-left: -45px;
  margin-top: -45px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 9px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.42);
}

.spinner-container .spinner-border {
  width: 3rem;
  height: 3rem;
}

.dialog-spinner {
  display: flex;
  width: 100%;
  justify-content: center;
}
.dialog-spinner .spinner-border {
  width: 2.5rem;
  height: 2.5rem;
}

.login-container {
  font-family: 'Dosis AZ';
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-position: center;
}

.login-container > .login-form {
  width: 390px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.login-form .bottom-text {
  font-size: 11pt;
  text-align: center;
  display: block;
  margin-top: 20px;
  color: #414141;
  cursor: pointer;
  font-weight: 400;
}
.blue-bold {
  color: #2d217e;
  font-weight: 700;
  background: linear-gradient(180deg, #bf7be9, #713198);
  padding: 7px 12px;
  color: white;
  /* margin-top: 7px; */
  border-radius: 20px;
  box-shadow: 0px 3px 6px 2px rgb(0 0 0 / 24%);
}

.login-form .form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.form-header .logo {
  height: 30px;
}

.form-group {
  position: relative;
}

.form-group label {
  font-size: 14px;
  margin: 0px;
  padding-left: 15px;
  color: #414141;
  margin-bottom: 7px;
  font-weight: 500;
}

/* .form-group.active input::placeholder {
  color:transparent;
} */

.form-group.active label {
  position: absolute;
  display: inline-block;
  top: -9px;
  color: #414141;
  background: white;
  left: 18px;
  padding: 0px 2px;
}

.form-check label {
  color: #414141;
  padding-left: 10px;
}

.form-check a {
  display: inline;
  color: #713198;
  font-weight: bold;
}

.form-control {
  background-color: #fff;
  font-size: 12pt;
  font-weight: 400;
  border: 1px solid rgb(21 1 33 / 30%);
  margin: 0px;
  height: 47px;
  border-radius: 22px;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2); */
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border: 1px solid #28a745;
}

.invalid-feedback {
  margin-left: 0.3rem;
  font-weight: 400;
}

.form-group {
  margin: 3px;
}

.login-form .form-check {
  margin: 10px;
}

.login-form .form-check input {
  height: 18px;
  width: 18px;
}

.language-dropdown.dropdown > button {
  border-radius: 11px;
  display: flex;
  background: white;
  position: relative;
  z-index: 101;
  justify-content: space-between;
  height: 45px;
}

.language-dropdown.dropdown > button::after {
  color: #2d217e;
}

.language-dropdown.dropdown > button:focus,
.language-dropdown.dropdown > button:active {
  box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 45%);
}

.language-dropdown.show > .btn-secondary.dropdown-toggle,
.language-dropdown.show > .btn-secondary.dropdown-toggle:focus {
  background-color: #efecec !important;
  box-shadow: 0px 3px 3px 0px rgb(0 0 0 / 45%);
}

.language-dropdown .dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language-dropdown .dropdown-item {
  padding-left: 12px;
}

.language-dropdown .dropdown-container img {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.language-dropdown.dropdown .dropdown-selected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}

.dropdown-selected .label {
  font-size: 12px;
  font-weight: 600;
  color: #2d217e;
  line-height: 12px;
}

.dropdown-selected .name {
  color: #414141;
}

.language-dropdown.dropdown .dropdown-menu.show {
  width: 100%;
  top: -20px !important;
  border-radius: 11px;
  z-index: 100;
  padding-top: 25px;
}

.form-control:focus {
  outline: none;
  border: 0;
}

.input-ticket-count {
  height: 36px;
  width: 53px;
  margin-left: 10px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
}

.title {
  color: #713198;
  font-size: 24pt;
  font-weight: 400;
  margin-left: 15px;
}

.btn {
  background: #4382f7;
  background: linear-gradient(180deg, #4382f7 0%, #0a54c9 100%);
  border: none;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;
  height: 50px;
  font-weight: 700;
  font-size: 18pt;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 28%);
}

.btn-purple {
  background: rgb(213, 154, 248);
  background: linear-gradient(
    180deg,
    rgba(213, 154, 248, 1) 0%,
    rgba(113, 49, 152, 1) 100%
  );
}

.modal-footer .btn {
  font-size: 15px;
  height: 40px;
  font-weight: 500;
}

.btn-yellow {
  background: rgba(255, 227, 177, 1);
  background: linear-gradient(
    180deg,
    rgba(255, 227, 177, 1) -20%,
    rgba(217, 114, 10, 1) 100%
  );
  background: linear-gradient(360deg, #f7981d 0%, #ffbc00 100%);
}

.btn-accept {
  background: #ffe3b1;
  background: linear-gradient(180deg, #ffe3b1 -20%, #d9720a 100%);
}

.btn-profile {
  font-weight: 500;
  font-size: 18px;
}

.btn-cancel {
  background: rgba(255, 227, 177, 1);
  opacity: 0.5;
  background: linear-gradient(180deg, #ffe3b1 -20%, #d9720a 100%);
}

.btn-image,
.btn-image:hover,
.btn-image:focus {
  height: 24px;
  background: #240735;
  /* border: 1px solid #240735; */
  font-size: 8pt;
  border-radius: 50px;
  padding: 0px 12px;
  width: auto;
  margin-left: 5px;
  font-weight: 500;
  box-shadow: none;
  box-shadow: 1px 2px 3px 0px rgb(0 0 0 / 50%);
  color: white;
  --color: #240735;
}

.btn-image:active {
  background-color: #240735 !important;
}

.btn-image:active img {
  filter: brightness(0) invert(1);
}

.btn-image img {
  height: 13px;
  padding-right: 8px;
}
.btn-image.auto {
  padding: 14px 22px;
  display: inline-flex;
}

.btn-image.auto.off {
  background: #c6bebe;
  /* width: 70px; */
  font-weight: 600;
  color: #606060;
  box-shadow: inset 0 1px 3px #000000;
}

.btn-image.auto.off svg {
  fill: #606060;
  stroke: #606060;
}

.btn-image.auto.on {
  background: linear-gradient(180deg, #57e26c 0%, #07ba23 100%);
  box-shadow: 0px 2px 3px 0px #28a7459e;
  outline: none !important;
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px #aef4af;
  }
  to {
    box-shadow: 0 0 5px 5px #aef4af;
  }
}

.btn-image.auto.off:active {
  background: #c6bebe !important;
  /* width: 70px; */
  font-weight: 600;
  color: #606060;
}

.btn-image.auto.on {
  fill: white;
  stroke: white;
}

.btn-image.auto.off:active svg {
  fill: #606060;
  stroke: #606060;
}

.button-spinner {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 5px;
  border-width: 0.18rem;
}

.btn-text {
  width: auto;
  display: inline;
  font-size: 10pt;
  height: auto;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid;
  background: none;
  color: #414141;
  font-weight: 500;
}

.btn-text.purple,
.btn-text.purple:focus {
  color: #713198;
  background-color: white;
  outline: none;
  box-shadow: none;
}

.btn-text.purple:active {
  color: white;
  background-color: #713198 !important;
  outline: none;
  box-shadow: none;
}

.dropdown-more {
  position: relative;
  background: none;
  box-shadow: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  padding: 0px;
  margin: 0px 4px;
}
.dropdown-more:focus {
  box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #713198;
}

.dropdown-more:after {
  position: absolute;
  width: 10px;
  top: 6;
  left: 50%;
  margin-left: -5px;
  content: '\2807';
  color: #713198;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.dropdown-more:active {
  background: #f1f1f1f1 !important;
  outline: none !important;
}
.dropdown-more:hover,
.dropdown-more:focus {
  background: none !important;
  outline: none !important;
}

.circle {
  position: relative;
  width: 36px;
  outline: none;
  height: 36px;
  border: none;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.46);
}

.circle.minus-button {
  background: linear-gradient(0deg, #f83030, #fa6363);
}

.circle.plus-button {
  /* background-color: #59c99e; */
  background: linear-gradient(0deg, #14a16b, #59c99e);
}

.plus-button::after,
.plus-button::before,
.minus-button::before {
  content: '';
  display: block;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-button::after {
  height: 1em;
  width: 0.2em;
}

.plus-button::before,
.minus-button::before {
  height: 0.2em;
  width: 1em;
}

.plus-button:active {
  box-shadow: 0 0 0 3pt #59c99e78, 0px 3px 6px rgb(0 0 0 / 46%),
    0 0 0 6pt #3a927087;
}

.minus-button:active {
  box-shadow: 0 0 0 3pt #fa636363, 0px 3px 6px rgb(0 0 0 / 46%),
    0 0 0 6pt #fa636378;
}

/* .circle:focus {
  outline: none !important;
}

.circle:hover {
  background: #f2f2f2;
}

.circle:active {
  background: #240735;
} */

.circle:active::after,
.circle:active::before {
  background-color: white;
}

.modal-body {
  padding: 20px 14px;
  padding-top: 0px;
  color: #444444;
  font-weight: 400;
}

.modal-body-list {
  max-height: 400px;
}

.modal-body-list::-webkit-scrollbar {
  display: none;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.modal-title {
  color: #713198;
  font-size: 22px;
}

.modal-content {
  border-radius: 20px;
  margin: 20px;
}

.modal-header {
  padding: 20px 14px;
  padding-bottom: 10px;
  border: none;
}
.modal-footer {
  border: none;
  padding: 10px;
  flex-wrap: nowrap;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem 0rem -1rem 0rem;
}

span.manat {
  font-size: 18px;
}

span.manat::after {
  /* content: "₼"; */
  content: url('assets/images/svg/manat.svg');
  font-size: 1.2em;
  font-weight: 500;
  padding-left: 2px;
}

/*  ----------LOTO ticket css-------------- */
.modal-ticket-edit .modal-content {
  margin: 0px;
}

.ticket {
  display: flex;
  border-radius: 10px;
  background: white;
  padding: 0px;
  flex-direction: column;
  border: 10px;
  /* -webkit-box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 46%); */
  background-color: #ffffff;
}

.ticket-row {
  display: flex;
  align-self: flex-start;
}

.ticket-cell {
  display: flex;
  flex: 1;
  min-width: 0;
  color: black;
  /* color: rgb(61, 62, 61); */
  background: transparent;
  align-items: center;
  justify-content: center;
  border: 1px solid #414141;
  font-weight: 700;
  /*font-size: 21px;*/
  font-size: min(4.6vw, 21px);
  padding: 2px 0px;
  margin: 0px;
  margin-top: -1px;
  margin-left: -1px;
  border-radius: 5px;
  outline: none;
}

div.ticket-cell .editable {
  text-align: center;
  background: none;
  width: 100%;
  height: 100%;
  color: rgb(61, 62, 61);
  border: none;
  font-weight: 700;
  font-size: 0.9em;
}

div.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

div.empty svg {
  opacity: 0.5;
}

.ticket.small .ticket-cell {
  font-size: 15px;
}

.ticket-cell:after {
  content: '';
  padding-top: 100%;
}

button.ticket-cell:hover,
button.ticket-cell :active,
button.ticket-cell:focus {
  outline: none;
}

.ticket-border1 {
  /*border: 1px solid black;
  padding: 3px;
  border-radius: 10px;
  */
  border: none;
}

.ticket-border2 {
  border: 1px solid black;
  padding: 2px;
  padding-left: 3px;
  padding-top: 3px;
  border-radius: 7px;
}

.ticket.selected {
  background: #607d8b;
}

.ticket.selected button.ticket-cell {
  color: white;
  border: 1px solid white;
}

.ticket.clickable:active {
  background: #240735;
}

.ticket.clickable:active button.ticket-cell {
  color: white;
  border-color: white;
}

button.ticket-cell:not(.disabled):not(.missed):active,
.success,
.pending {
  position: relative;
}

button.ticket-cell:not(.disabled):not(.missed):active:before,
.success:before,
.pending:before {
  content: '';
  position: absolute;
  width: 80%;
  height: 72%;
  margin-top: -1px;
  border-radius: 23.5px;
  background-color: #4382f7cc;
}

button.ticket-cell:not(.disabled):not(.missed):active:before,
.pending:before {
  background-color: #4382f755;
}

button.ticket-cell:not(.disabled):not(.missed).yellow:active:before,
.success.yellow:before {
  background-color: #f1b750cc;
}

button.ticket-cell:not(.disabled):not(.missed).yellow:active:before {
  background-color: #f1b750cc;
}

button.ticket-cell:not(.disabled):not(.missed).yellow:active:before,
.pending.yellow:before {
  background-color: #f1b75055;
}

button.ticket-cell:not(.disabled):not(.missed).purple:active:before,
.success.purple:before {
  background-color: #713198cc;
}

button.ticket-cell:not(.disabled):not(.missed).purple:active:before {
  background-color: #713198cc;
}

button.ticket-cell:not(.disabled):not(.missed).purple:active:before,
.pending.purple:before {
  background-color: #71319855;
}

button.ticket-cell:not(.disabled):not(.missed).success:active:before {
  display: none;
}

.missed {
  position: relative;
  padding: 0px;
}

.missed:before,
.missed:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 3px;
  background-color: #af111c;
  transform: rotate(45deg);
  -webkit-box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.5);
  box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.5);
}

.missed:after {
  transform: rotate(-45deg);
  padding: 0px;
}

.ticket-cell:empty {
  background-color: #73737359;
  /*box-shadow: inset 2px 2px 0px rgba(255, 255, 255, 1),
    inset -2px 2px 0px rgba(255, 255, 255, 1),
    inset 2px -2px 0px rgba(255, 255, 255, 1),
    inset -2px -2px 0px rgba(255, 255, 255, 1);
    */
}
/*  ---------- end of ticket css-------------- */

.rounded-bar.blue {
  animation: shake 8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  animation-iteration-count: infinite;
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }

  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }

  3%,
  5%,
  7% {
    transform: translate3d(-4px, 0, 0);
  }

  4%,
  6% {
    transform: translate3d(4px, 0, 0);
  }
  10%,
  100% {
    transform: translate3d(-1px, 0, 0);
  }
}

.modal-progress .modal-content {
  background: white;
}

.modal-progress .modal-content .modal-body {
  display: flex;
  color: #444444;
  align-items: center;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
  padding-top: 15px;
}

.lds-circle > div {
  display: inline-block;
  width: 41px;
  height: 41px;
  margin: 6px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  border: 5px solid #713198;
  color: #713198;
  font-size: 14pt;
  font-weight: 600;
  background: transparent;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.58);
  -webkit-animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

/* ----- LOTO KEG ---------------*/

.number-list {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50px;
}

.keg-hole {
  position: relative;
  flex: 1 0 auto;
  background: #40145c;
  color: #ffffff;
  margin: 5px;
  border-radius: 50%;
  box-shadow: inset 0px 6px 10px 0px rgba(0, 0, 0, 0.75);
}

.keg-hole.main {
  flex-basis: 15px;
}

.loto-keg-new span {
  font-size: 1.8rem;
  position: absolute;
  color: #de9411;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loto-keg-new {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #b57e1c;
  /* box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.55), 0px 5px 0px #8b5d0b,
    0px 5px 0px #8b5d0b, 1px 11px 5px rgba(0, 0, 0, 0.66); */
  background-color: #efecec;
  --background-image: url('assets/images/kegbg.png');
}

.loto-keg-new span {
  /* font-family: "Dosis AZ"; */
  font-family: Helvetica;
  font-weight: 700;
  margin-left: 0px;
  text-align: center;
  position: absolute;
  color: #240735;
  text-shadow: -1px 0 white, 0 1px white, 1px 0px white, 0px -1px white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.keg-hole.main .loto-keg-new {
  position: absolute;
  border: 6px solid #de9411;
  /* box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.55), 0px 5px 0px #8b5d0b,
    1px 11px 5px rgba(0, 0, 0, 0.66); */
}

.keg-hole.main .loto-keg-new span {
  font-size: 2.3rem;
  color: #240735;
}

.keg-hole:before {
  content: '';
  float: left;
  padding-top: 100%;
}

@media screen and (max-width: 360px) {
  .play-info {
    font-size: 0.7rem;
  }
}

@media screen and (max-height: 600px) {
  .ticket-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-height: 650px) {
  .login-container > .login-form .form-group label {
    display: none;
  }
}

@media screen and (max-width: 359px) {
  .jackpot-container span {
    font-size: 12px;
  }

  .ticket-cell {
    font-size: 14px;
  }
  .loto-keg-new span {
    font-size: 1.3em;
  }
}
/* 
.modal {
  color: #414141;
  z-index: 2000;
  background: #00000094;
}
.modal-content {
  border-radius: 10px !important;
  padding: 5px;
  margin: 5px;
  font-size: 17px;
  font-weight: 100;
}
.modal.transparent {
  color: white;
  overflow-y: auto !important;
}
.modal.transparent .modal-content {
  background: transparent;
  border: none;
}
.modal.transparent .modal-body {
  display: flex;
  flex-direction: column;
}

.modal.transparent span.info {
  font-size: 11pt;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 1px dotted #ffffff82;
}

.modal-body {
  overflow-y: auto;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.modal-title {
  font-size: 14px;
  font-weight: 500;
  color: #713198;
  text-transform: uppercase;
}
.modal-header {
  border: none;
}

.modal-footer {
  border: none;
} */

.connection-bar {
  position: absolute;
  background: green;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 5px;
  text-align: center;
  line-height: 1;
  margin: 0;
}

.confetti .modal-content::before {
  content: '';
  background-image: url('assets/images/confetti.png');
  background-size: initial;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.medal-background .modal-content::after {
  position: absolute;
  display: inline-block;
  background-size: 100px 100px;
  right: 3%;
  background-repeat: no-repeat;
  background-image: url(assets/images/winner.png);
  content: ' ';
  z-index: 0;
  opacity: 0.3;
  height: 100px;
  width: 100px;
}

.sad-background .modal-content::after {
  position: absolute;
  display: inline-block;
  background-size: 100px 140px;
  right: 3%;
  top: 15px;
  background-repeat: no-repeat;
  background-image: url(assets/images/sad.png);
  content: ' ';
  z-index: 0;
  opacity: 0.1;
  height: 100px;
  width: 100px;
}

.clock-background .modal-content::after {
  position: absolute;
  background-size: 130px 130px;
  right: 3px;
  top: 22px;
  margin-bottom: 0px;
  margin-right: 0px;
  background-repeat: no-repeat;
  background-image: url(assets/images/clock_new.png);
  content: ' ';
  z-index: 0;
  opacity: 0.07;
  height: 130px;
  width: 130px;
}

.game-result-info {
  color: #414141;
  font-weight: 400;
}

.sad-background .game-result-info {
  display: flex;
  margin-right: 60px;
}

.modal-footer {
  z-index: 10;
}

.modal-header .close {
  margin: 0px;
  padding: 0px;
  margin-right: 5px;
}

.list-of-winners-bar {
  display: block;
  justify-content: space-between;
  border-radius: 22px;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  align-items: center;
  /* align-items: center; */
  max-height: 340px;
  overflow: auto;
  padding: 0px 16px;
  min-height: 44px;
}

.winner-user-info {
  display: flex;
  align-items: center;
}

.winner-user-info .winner-name {
  font-weight: 500;
  font-size: 13px;
  margin-left: 5px;
  color: #240735;
}

.winner-user-info .winner-prize {
  margin-left: auto;
  font-size: 15px;
  font-weight: 500;
  color: #d9720a;
}

.list-of-winners-bar .collapse.show {
  margin-bottom: 10px;
}

.profile-username {
  display: flex;
  align-items: center;
}

.profile-username img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  border: 4px solid #f1b750;
}

.profile-username .username {
  font-size: 18px;
  width: 100px;
  line-height: 20px;
  text-align: right;
}

.profile-balance {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* background: #de1623; */
  height: 58px;
  border-radius: 44px;
  /* position: absolute; */
  bottom: 0;
  margin-top: 13px;
  margin-right: 7px;
  margin-left: 7px;
  margin-bottom: -50px;
  background: #ffe3b1;
  background: linear-gradient(180deg, #ffe3b1 -20%, #d9720a 100%);
}

.profile-balance .amount {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.profile-balance .amount .label {
  line-height: 17px;
  font-size: 16px;
  font-weight: 400;
}
.profile-balance .amount .balance {
  line-height: 27px;
  font-size: 25px;
  font-weight: 500;
  text-shadow: 0px 1px #a56d0b;
}

.profile-balance .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 5px;
}

.profile-balance .plus {
  position: relative;
  margin-left: auto;
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  margin-right: 14px;
  justify-content: center;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 40%);
}

.label-number {
  background: red;
  display: flex;
  right: 0;
  top: -3px;
  position: absolute;
  margin: 2px;
  border-radius: 50px;
  width: 25px;
  font-weight: bold;
  color: #ffffff;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  height: 24px;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 38%);
}

span.section-title {
  padding-left: 15px;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
}

.btn-img {
  font-size: 10px;
  padding: 3px 10px;
  margin-right: 0px;
  padding: 0px 5px;
  margin: 0px;
  background: none;
  box-shadow: none;
  color: black;
  width: auto;
  height: auto;
}

.btn.btn-menu {
  display: flex;
  margin: 6px 0px;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  background: #713198;
}

.btn.btn-menu .icon-container {
  background: white;
  display: flex;
  border-radius: 50%;
  padding: 0px;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.btn.btn-menu .chevron-right {
  margin-left: auto;
  margin-right: 3px;
}

.btn.btn-menu .icon-container svg {
  height: 16px;
}

.btn.btn-menu .icon-container svg path {
  fill: #713198;
}

.btn .title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn span {
  color: white;
}

.btn .title {
  padding: 0;
  margin: 0;
  line-height: 17px;
  font-size: 16px;
  font-weight: 500;
}

.btn .subtitle {
  padding: 0;
  margin: 0;
  line-height: 15px;
  color: #ffffff82;
  font-size: 12px;
  font-weight: 500;
}

.btn.btn-menu:active svg path {
  fill: white;
}
.btn.btn-menu:active .icon-container {
  background: #713198;
}

.btn.btn-menu:active .subtitle,
.btn.btn-menu:active .title {
  color: #713198;
}

.btn.btn-menu:active {
  background-color: white !important;
  outline: none;
  border-color: white !important;
}

.gg-math-plus,
.gg-math-plus::after {
  display: block;
  color: #e59c4b;
  box-sizing: border-box;
  background: currentColor;
  border-radius: 10px;
}
.gg-math-plus {
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
}
.gg-math-plus::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  top: -7px;
  left: 7px;
}

.section-header {
  font-size: 24px;
  font-weight: 500;
  padding-left: 16px;
  padding-top: 30px;
}

.page-container {
  padding-top: 40px;
  padding-bottom: 20px;
}

.login-form.profile label {
  color: white;
}

.login-form.profile input {
  background: #ffffff00;
  border: 1px solid #ffffff55;
  color: white;
  font-weight: 500;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}

.login-form.profile input::placeholder {
  color: #ffffff33;
}

.login-form.profile input:focus {
  background: white;
  color: #713198;
}

.login-form.profile .disabled input:disabled {
  background: #ffffff;
  color: #713198;
}
.login-form.profile .disabled {
  opacity: 0.6;
}

div.settings-section {
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid #ffffff47;
}

div.section-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

div.settings-section .section {
  font-size: 16px;
}

div.settings-section .description {
  font-size: 14px;
  font-weight: 300;
}

.btn-number {
  width: 35px;
  height: 35px;
  margin: 4px;
  margin-left: 20px;
}

.btn-number.yellow {
  background: #f1b750;
}
.btn-number.blue {
  background: #4382f7;
}

.btn-number.purple {
  background: #713198;
}

.btn-number.selected {
  border: 3px solid white;
}

.beta-label {
  position: absolute;
  left: -19px;
  top: -1px;
  color: white;
  background: #dc3545;
  /* border: 1px solid white; */
  /* text-shadow: 0 0 black; */
  padding: 1px 9px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
}

.flag-selection {
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #ffffff44;
  align-items: center;
}

img.flag {
  height: 30px;
  width: 30px;
}

.flag-selection span {
  margin-left: 15px;
}

.flag-selection svg {
  margin-left: auto;
}

.social-contacts img {
  width: 33px;
  margin: 10px;
}

@keyframes hop {
  from {
    -webkit-transform: translate(0px, 0px);
  }

  to {
    -webkit-transform: translate(0px, -10px);
  }
}

@-moz-keyframes hop {
  from {
    -moz-transform: translate(0px, 0px);
  }

  to {
    -moz-transform: translate(0px, -10px);
  }
}

#there {
  color: #fff;
  border-color: #0668b9;
  background-color: #007bff;
}
#there {
  position: absolute;
  bottom: -45px;
  font-size: 12px;
  right: -4px;
  font-weight: bold;
  font-family: Verdana, Geneva, sans-serif;
  border-radius: 5px;
  border-width: 0 1px 1px 1px;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  padding: 7px;
  -webkit-border-radius: 5px;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 1px 0;
  box-shadow: 0px 0px 17px #007bff;
}

.bounce {
  animation-name: hop;
  animation-duration: 0.3s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  -webkit-animation-name: hop;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: hop;
  -moz-animation-duration: 0.3s;
  -moz-animation-direction: alternate;
  -moz-animation-timing-function: linear;
  -moz-animation-delay: 0s;
  -moz-animation-iteration-count: infinite;
}

#here:after,
#there:after {
  /*arrow added to downarrowdiv DIV*/
  content: '';
  display: block;
  position: absolute;
  bottom: 100%; /*should be set to 100% */
  width: 0;
  height: 0;
  border: 7px solid;
  margin-left: -10px;
  right: 18px;
}

#there:after {
  /*arrow added to downarrowdiv DIV*/
  border-color: transparent transparent #007bff transparent; /*border color should be same as div div background color*/
}

.formatted-text h1 {
  font-size: 24px;
}

.formatted-text h1,
.formatted-text h2 {
  color: #713198;
  font-family: Circe Bold, sans-serif;
}

.formatted-text h2 {
  font-size: 22px;
}

.formatted-text h3,
.formatted-text p {
  font-size: 16px;
  margin: 0;
}

.formatted-text a {
  display: inline;
  color: #713198;
  text-decoration: underline;
}

.formatted-text .list .list__item:before,
.formatted-text .list li:before,
.formatted-text ul .list__item:before,
.formatted-text ul li:before {
  content: '•';
  color: #713198;
  font-weight: 700;
  font-family: Circle Bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font: normal 20px / 28px Circe Bold, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font: normal 24px /35px Circe Bold, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.footer-info-container {
  background: #00000047;
  border-top: 1px solid #ffffff40;
  font-size: 11px;
  color: #f8f9faa6;
}

.footer-info-container .heading {
  font-size: 13px;
  color: white;
  font-weight: 500;
  margin-bottom: 2px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.footer-links a {
  display: flex;
  color: #f8f9faa6;
  align-items: flex-start;
}

.footer-contact {
  margin-left: auto;
}

.footer-contact svg {
  height: 18px;
  width: 18px;
  fill: #ffffffa0;
  margin-left: 4px;
}

.bg-gradient-purple {
  background-color: transparent;
  background: linear-gradient(99.4deg, #676eff 0%, #713198 100%);
}

.wallet {
  border-radius: 20px;
  margin: 15px 0px;
}

.btn-wallet {
  display: flex;
  flex: 1;
  color: #6c4fcb;
  padding: 20px 0px;
  justify-content: space-between;
  background: white;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0px !important;
  padding: 23px 15px !important;
}

.btn-wallet.disabled {
  pointer-events: none;
  box-shadow: none;
}

.wallet-form input {
  margin-bottom: 25px;
}

.btn-outline {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: white !important;
  color: #713198 !important;
  border: 1px solid #713198 !important;
  padding: 3px 10px;
  margin: 0;
  width: auto;
  font-size: 12px;
  height: auto;
}

.btn-outline.active {
  background: #713198 !important;
  color: white !important;
}

.money-box-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 10px 10px;
  max-width: 100%;
  overflow-x: auto;
}

.money-box-container .btn-outline {
  padding-right: 5px;
  margin-right: 5px;
  box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 35%);
  font-weight: 600;
  margin: 4px;
}

.chat-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: absolute;
  box-shadow: 1px 2px 3px 0px rgb(0 0 0 / 50%);
  background: rgb(113, 49, 152);
  height: 50px;
  border-radius: 50px;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
}

.chat-box span {
  margin-left: 10px;
  margin-right: 5px;
}
/* CARD FORM  */
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  border-radius: 10px;
  padding: 35px 0px;
}
.card-form__inner {
  padding: 25px;
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
  }
}
.card-form__row {
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__row {
  flex-wrap: wrap;
}
.card-form__col {
  flex: auto;
  margin-right: 0;
  flex: unset;
  width: 100%;
  margin-bottom: 20px;
}
.card-form__col:last-child {
  margin-right: 0;
}
.card-form__col:last-child {
  margin-bottom: 0;
}
.card-form__col.-cvv {
  max-width: 150px;
  max-width: initial;
}
.card-form__col.-cvv {
  max-width: initial;
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #2364d2;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
.card-form__button {
  margin-top: 10px;
}
.card-list {
  margin-bottom: -130px;
}
.card-list {
  margin-bottom: -120px;
}
.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) {
  .github-btn:hover {
    transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
@media screen and (max-width: 700px) {
  .github-btn {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;
  }
  .github-btn:active {
    transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}

.form-error {
  padding: 0px 3px;
  font-size: 14px;
  color: red;
}

.wallet-balance {
  color: white;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  border-radius: 20px;
  margin-top: 10px;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 45%);
}

.card-input.error input,
.card-form__row.error .card-form__group select {
  border-color: red !important;
}

.card-type-brand {
  font-weight: 600;
}

@media screen and (max-width: 370px) {
  .card-type-brand {
    font-size: 10px;
  }
}

.jackpot {
  position: relative;
  margin-top: 10px;
  margin-left: -5px;
}

.jackpot img {
  position: absolute;
  left: -41px;
  height: 72px;
  top: -24px;
}

.jackpot section {
  position: absolute;
  left: -10px;
}

.jackpot-sign {
  color: #ffc107;
  font-weight: bold;
  font-size: 13px;
  margin-top: 2px;
  text-shadow: 0px 1px 1px #0c0808;
  margin-left: -19px;
  padding-left: 6px;
}

.jackpot-sign.draw {
  color: #ff0018;
  text-shadow: 0px 1px 9px #ff1027;
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.jackpot-sign.others {
  font-size: 30px;
  z-index: 100;
}

.bouncing-text {
  position: relative;
  display: flex;
  font-size: 40px;
  font-weight: 900;
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
}

.b,
.o,
.u,
.n,
.c,
.e,
.d {
  position: relative;
  color: #ffc107;
  font-weight: bold;
  font-size: 35px;
  margin-top: 2px;
  text-shadow: 0px 1px 2px #0c0808;
  margin-bottom: -26px !important;
  text-shadow: 0 1px 0 #d9720a, 0 2px 0 #d9720a, 0 3px 0 #d9720a,
    0 4px 0 #d9720a, 0 5px 0 #d9720a, 0 6px 0 transparent, 0 7px 0 transparent,
    0 8px 0 transparent, 0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
}

.b {
  animation: bounce 1s ease infinite;
  -webkit-animation: bounce 1s ease infinite;
}

.o {
  animation: bounce 1s ease infinite 0.1s;
  -webkit-animation: bounce 1s ease infinite 0.1s;
}

.u {
  animation: bounce 1s ease infinite 0.2s;
  -webkit-animation: bounce 1s ease infinite 0.2s;
}

.n {
  animation: bounce 1s ease infinite 0.3s;
  -webkit-animation: bounce 1s ease infinite 0.3s;
}

.c {
  animation: bounce 1s ease infinite 0.4s;
  -webkit-animation: bounce 1s ease infinite 0.4s;
}

.e {
  animation: bounce 1s ease infinite 0.5s;
  -webkit-animation: bounce 1s ease infinite 0.5s;
}

.d {
  animation: bounce 1s ease infinite 0.6s;
  -webkit-animation: bounce 1s ease infinite 0.6s;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-25px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  58% {
    transform: scale(1, 1) translateY(-7px);
  }
  65% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-15px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  58% {
    transform: scale(1, 1) translateY(-7px);
  }
  65% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.bouncing-text:before {
  left: 0;
  animation: scale 1s linear infinite;
  -webkit-animation: scale 1s linear infinite;
}

@keyframes scale {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(0.4);
  }
  50% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}

@-webkit-keyframes scale {
  0% {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(0.4);
  }
  50% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}

.bouncing-text:after {
  left: 57px;
  animation: scale 1s linear infinite 0.1s;
  -webkit-animation: scale 1s linear infinite 0.1s;
}

.jackpot-sign-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 95px;
}

.bounty,
.jackpot-winner-prize {
  font-size: 42px;
  color: #ffbf00;
  margin: 6px;
  font-weight: bold;
  fill: #ffbf00;
  margin-top: 5px;
  text-shadow: 0px 3px 5px #000;
  animation: zoom-in-zoom-out 1s ease infinite;
}

.no-animation .bounty,
.no-animation .jackpot-winner-prize {
  animation: none !important;
}

.bouncing-text.no-animation div {
  animation: none !important;
}

.countdown-timer {
  position: absolute;
  right: 13px;
  border-radius: 50%;
  border: 3px solid red;
  padding: 5px;
  top: 15px;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 19px;
  box-shadow: 1px 2px 3px rgb(0 0 0 / 55%);
  z-index: 10000;
  display: flex;
  color: red;
  font-weight: 500;
  justify-content: center;
}

.ticket.jackpot-winner {
  background: linear-gradient(
    90deg,
    #de9411 0%,
    #ffd791 26.1%,
    #f2c370 52.2%,
    #ffad1a 75.9%,
    #ffdda2 100%
  );
}
.ticket.jackpot-winner .ticket-border2 {
  background: #ffffff8a;
}

.ticket.jackpot-winner .ticket-border1 {
  padding: 6px;
}

.expand-container {
  overflow: hidden;
}

.expand-content {
  /* height: 0; */
  margin-top: -100%;
  transition: all 1s;
}

.expand-content.expanded {
  /* height: 0; */
  /* margin-top: 0; */
  margin-top: 0;
  /* transform: scaleY(1); */
  /* transform: skew(1); */
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.jackpot-winner-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
}

.jackpot-winner {
  color: #de9411;
  text-shadow: 1px 1px 0px #343a40;
  font-size: 22px;
  text-align: center;
}

.ticket.mini {
  width: 100%;
}
.ticket.mini .ticket-cell {
  font-size: 0.6rem;
}

.scroll-container {
  overflow: auto;
  height: 100%;
}

.react-datepicker__navigation-icon {
  top: 13px !important;
}

.loto-calendar.react-datepicker {
  border: 0px;
}

.loto-calendar .react-datepicker__header {
  background-color: #713198;
  color: white !important;
}
.loto-calendar .react-datepicker__current-month {
  color: white;
}

.loto-calendar .react-datepicker__day-name {
  color: white;
}

.loto-calendar .react-datepicker__day--in-range {
  background-color: #713198;
}

.loto-calendar .react-datepicker__day {
  margin: 0;
  border-radius: 0 !important;
}

.loto-calendar
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #b768e8;
}

.date-range-container {
  display: flex;
}
.date-range-container > div {
  display: flex;
  flex: 1;
  margin: 3px;
}

.calendar-picker {
  display: flex;
  width: 100%;
  background: transparent;
  color: #f1b750;
  border-radius: 10px;
  font-size: 16px;
  justify-content: space-between;
  padding: 5px 15px;
  line-height: 22px;
  border: 1px solid #f1b750;
}

.calendar-picker svg {
  margin-bottom: 5px;
  align-self: flex-end;
}

.scroll-container > div {
  display: flex;
  flex-direction: column;
}

.ranking {
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 7px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}

.ranking .place {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  margin-right: 10px;
  color: white;
}

.ranking .player {
  font-weight: 500;
  flex: 24%;
  font-size: 14px;
  font-family: 'Dosis';
  font-size: 17px;
  color: #f0f0f0;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
}

.ranking .prize {
  font-family: Dosis;
  margin-left: auto;
  display: flex;
  /* flex: 24% 1; */
  flex-shrink: 0;
  color: white;
  float: left;
  justify-content: center;
  border: 1px solid white;
  align-items: center;
  border-radius: 7px;
  padding: 0px 13px;
  font-weight: 600;
  text-shadow: 1px 1px #075c1b;
  background: linear-gradient(180deg, #a5ec23 0%, #41ac07 100%);
}
.ranking .coins svg {
  height: 20px;
  width: 20px;
  filter: drop-shadow(1px 2px 1px rgb(0 0 0 / 0.8));
}

.ranking .coins svg path {
  fill: #ffc107;
}

.ranking .prize svg {
  margin-right: 5px;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6));
}
/* .ranking .prize svg path {
  fill: #2a5;
} */

.ranking .coins {
  margin-left: auto;
  display: flex;
  color: #ffc107;
  text-shadow: 0px 1px 2px #000000;
  flex-shrink: 0;
  flex: 22%;
  font-weight: 600;
  align-items: center;
  justify-content: flex-end;
}

.ranking.sticky {
  position: sticky;
  bottom: 6px;
  background: linear-gradient(
    180deg,
    rgba(255, 211, 128, 1) -20%,
    rgba(198, 138, 43, 1) 100%
  );
  border-radius: 2px;
  border: none;
  color: black;
  padding-left: 10px;
  padding-right: 10px !important;
}

.ranking.sticky .place {
  border-color: black;
  border: none;
}

.ranking-remaining {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.ranking-remaining div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px;
  font-size: 10px;
  padding: 6px 0px;
  font-weight: 300;
  border-radius: 15px;
  width: 60px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(198, 138, 43, 0.3) 100%
  );
}

.ranking-remaining span:first-child {
  font-size: 25px;
  font-weight: 500;
  line-height: 25px;
}

.btn-svg-blue,
.btn-svg-blue:active {
  background: linear-gradient(180deg, #467afc 0%, #2d217e 100%);
  padding-left: 4px !important;
  align-items: center;
}

.btn-svg-blue:hover,
.btn-svg-blue:active {
  background: #467afc !important;
}

.btn-svg-blue svg {
  margin-right: 5px;
}

.ranking .place.first,
.ranking .place.second,
.ranking .place.third {
  background: none;
  border: none;
}

.ranking.first,
.ranking.second,
.ranking.third {
  background: rgba(255, 255, 255, 0.14);
  border-radius: 0;
}

.ranking .place img {
  height: 34px;
}

.ranking.sticky .place img {
  background: #fff2cdf0;
  border-radius: 25px;
  padding: 3px;
  box-shadow: inset 0px -1px 3px #5e3205;
}

.game-star svg path {
  fill: #ffc107;
}

.game-star svg {
  width: 14px;
  height: 14px;
}

.game-star {
  color: #ffc107;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.ticket-row.invalid {
  background-color: yellow;
}

.ticket-row.invalid input {
  color: black;
}

.ticket-cell.invalid {
  background: red;
}

.ticket-cell.invalid input {
  color: white;
}

/* .ticket.editable .ticket-cell {
  box-shadow: inset 2px -2px 2px #0000004a, inset -2px 1px 3px #00000052;
} */

.btn-green,
.btn-green:active {
  background: rgb(0, 200, 93) !important;
}
.btn-green:hover,
.btn-green:active {
  background: rgb(0, 200, 93) !important;
}

.btn-orange,
.btn-orange:active {
  background: orange;
}

.btn-orange:hover,
.btn-orange:active {
  background: orange;
}

.btn-disabled {
  background: linear-gradient(0deg, #808080b8, #dedbdb);
}

ul.ticket-errors {
  margin-top: 10px;
  padding-left: 25px;
  margin-bottom: 0px;
}
ul.ticket-errors li {
  font-weight: 500;
  color: red;
}

.btn-more,
.btn-more:active {
  background: white;
}
.btn-more:hover,
.btn-more:focus {
  background: grey;
}

.ticket-info .coins {
  font-weight: 600;
}

.ticket-info .coins svg path {
  fill: #ffc107;
}

.ticket-info .prize svg.money {
  margin-right: 5px;
}

.ticket-info .prize svg.money path {
  fill: #34c38f;
}

.notification-badge {
  display: block;
  position: absolute;
  padding: 4px;
  background: #dc3545;
  right: -2px;
  top: -4px;
  border-radius: 50%;
  box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 30%);
}

.notification-badge.number {
  font-size: 10px;
  width: 18px;
  height: 18px;
  align-items: center;
  background: linear-gradient(180deg, #ec5d5d, red);
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: center;
}

.coin-container {
  top: -5px;
  color: #ffc107;
  right: 0px;
  margin-top: 1px;
  font-size: 9px;
  font-weight: 500;
  display: flex;
  /* text-shadow: 0px 1px #c19207; */
  flex-direction: column;
  align-items: flex-end;
  background: green;
  height: 33px;
  margin-left: -25px;
  padding-left: 30px;
  padding-right: 12px;
  /* position: absolute; */
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: #2e363c;
  display: flex;
  align-items: center;
}
.coin-container .multiplier {
  background-color: red;
  position: absolute;
  border-radius: 50%;
  top: -2px;
  right: -8px;
  color: white;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-buy {
  width: 100%;
  height: auto;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 2px 14px;
  gap: 4px;
  /* position: absolute; */
  /* width: 184px; */
  /* height: 54px; */
  /* left: 154px; */
  /* bottom: 24px; */
  /* background: linear-gradient(180deg, #e85ee0 0%, #a80c9f 100%); */
  box-shadow: 0px 2px 2px rgb(0 0 0 / 25%), inset 0px -4px 0px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.btn-buy div span:nth-child(1) {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 1px #0000005c;
}

.btn-buy div span:nth-child(2) {
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffc107;
}

.btn-buy div span:nth-child(2) svg path {
  fill: #ffc107;
}

.last-event-coins {
  color: #ffc107;
  font-weight: 600;
  font-size: 20px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.43);
}

.last-event-coins svg {
  width: 20px;
  height: 20px;
  margin-top: -5px;
  margin-left: -2px;
}
.last-event-coins svg path {
  fill: #ffc107;
}
.last-event-prize span path {
  fill: green;
}

.last-event-prize {
  font-family: Dosis;
  margin-left: auto;
  display: inline-flex;
  /* flex: 24% 1; */
  flex-shrink: 0;
  color: white;
  /* float: left; */
  justify-content: center;
  border: 1px solid white;
  align-items: center;
  border-radius: 7px;
  padding: 0px 13px;
  font-weight: 600;
  font-size: 20px;
  text-shadow: 1px 1px #075c1b;
  background: linear-gradient(180deg, #a5ec23 0%, #41ac07 100%);
}

.last-event-prize svg {
  margin-left: 5px;
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.6));
}

.last-event-modal .modal-content {
  background-color: #713198;
}

.last-event-modal .modal-title {
  color: #ffc107;
  text-shadow: 1px 2px black;
}

.last-event-modal .modal-body {
  color: white;
}

.ranking-date {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

@media screen and (max-width: 405px) {
  .ranking-date {
    font-size: 21px;
    line-height: 21px;
  }

  .ranking-remaining div {
    width: 53px;
  }

  .ranking-remaining span:first-child {
    font-size: 23px;
  }
}

.deposit-section {
  background-color: #713198;
  /* box-shadow: 0px 3px 4px rgb(0 0 0 / 41%); */
}

.card-type-brand {
  color: #414141;
  font-weight: 500;
}

.payment-method {
  width: 100%;
  max-width: 32%;
  height: 30.3vw;
  max-height: 128px;
  background: white;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-bottom: 6px;
  border: 1px solid #0000002e;
  margin: 3px;
}

.payment-method.active {
  background: #ffffff;
  border: 2px solid #ffc107;
  box-shadow: 1px 1px 11px 2px #71319869;
}

.payment-method img {
  max-width: 50%;
  display: block;
  height: auto;
  margin: auto;
  overflow: hidden;
}

.payment-method span {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #6b7d8e;
}
.deposit-input {
  margin-top: 3px;
}

.deposit-input input {
  border-radius: 3px !important;
  border: 1px solid #adaaaa7d !important;
  padding: 3px 14px;
  font-weight: 500;
  box-shadow: inset 2px 2px 2px #71319840;
}

.deposit-input .error {
  color:#fa0000;
  border: 1px solid #fa0000 !important;
}
